*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.div1{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    height:65px;
    width:100vw;
    background-color:#ffffff22;
}

.logo{
    height:100px;
    width:130px;
    margin-Left:60px;
    
}
@media (max-Width:1024px){
    .div1{
        display:flex;
        justify-content:flex-start;
        align-items:center;
        height:65px;
        width:100vw;
        background-color:#ffffff22;
    }
    
    .logo{
        height:100px;
        width:115px;
        margin-Left:30px;
        
    }
    
}
@media (max-Width:1000px){
    .div1{
        display:flex;
        justify-content:flex-start;
        align-items:center;
        height:65px;
        width:100vw;
        background-color:#ffffff22;
    }
    
    .logo{
        height:100px;
        width:115px;
        margin-Left:30px;
        
    }
    
}
