
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.footer-container{
width: 100vw;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
border-top: 2px solid gray;
text-align: center;
}